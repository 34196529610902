import { encodeParams, sendMessageToWidgets, handleMessageTypes } from './utils';

function embedHandler(iframeSelector, baseUrl) {
  function addIframeParams() {
    var ifr = document.querySelector(iframeSelector);
    if (ifr) {
      var { src } = ifr;
      if (src.includes('jsForm=true')) {
        return;
      }
      var parentURL = window.location.href;
      var queryParameters = '';
      if (parentURL && parentURL.indexOf('?') > -1) {
        queryParameters = parentURL.substring(parentURL.indexOf('?') + 1).split('&');
        queryParameters = encodeParams(queryParameters);
      }

      if (src && src.indexOf('?') > -1) {
        var iframeParams = src.substr(src.indexOf('?') + 1).split('&');
        iframeParams = encodeParams(iframeParams);
        queryParameters += `&${iframeParams}`;
        src = src.substring(0, src.indexOf('?'));
      }
      queryParameters += '&isIframeEmbed=1';
      ifr.src = `${src}?${queryParameters}`;
    }
  }

  function handleIFrameMessage(e) {
    if (typeof e.data === 'object') {
      return;
    }
    var args = e.data.split(':');
    var iframe = document.querySelector(iframeSelector);
    if (!iframe) {
      return;
    }

    // add base url to permitted domains
    var permittedDomains = [];
    if (baseUrl) {
      const { hostname } = new URL(baseUrl);
      permittedDomains = [hostname];
    } else {
      permittedDomains = ['jotform.com', 'jotform.pro'];
    }

    handleMessageTypes(args, e, permittedDomains, iframeSelector);

    sendMessageToWidgets(e, iframe, permittedDomains);
  }

  function isPermitted(originUrl, whitelistedDomains) {
    var url = document.createElement('a');
    url.href = originUrl;
    var { hostname } = url;
    var result = false;
    if (typeof hostname !== 'undefined') {
      whitelistedDomains.forEach(element => {
        if (
          hostname.slice(-1 * element.length - 1) === '.'.concat(element)
          || hostname === element
        ) {
          result = true;
        }
      });
      return result;
    }
  }

  // use functions
  addIframeParams();
  window.isPermitted = isPermitted;
  window.addEventListener('message', handleIFrameMessage, false);
}

window.jotformEmbedHandler = embedHandler;
